// ... (previous imports)

// import Layout from 'Components/Layout';
// import React, { useEffect, useState } from 'react';
// import { Helmet } from 'react-helmet';
// import { Services } from 'Services';
// import UserClasses from './Users.module.css';
// import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
// import { useNavigate, useParams } from 'react-router-dom';
// import BoxImg from '../../../Assets/box-img.png';
// import { IoPerson } from 'react-icons/io5';
// import { IoLocationSharp } from "react-icons/io5";
// import { IoIosCall } from "react-icons/io";
// import { FaRupeeSign } from "react-icons/fa";

// const EachUserOrder = () => {
//     const [orderDetails, setOrderDetails] = useState(null);
//     const { orderId } = useParams();
//     const [loading, setLoading] = useState(true);

//     const navigate = useNavigate('')

//     useEffect(() => {
//         const fetchOrderDetails = async () => {
//             try {
//                 const storedToken = localStorage.getItem('Token');
//                 if (!storedToken) {
//                     console.error('Authentication token is empty');
//                     return;
//                 }

//                 const token = { Authorization: `token ${storedToken}` };
//                 const response = await Services.getOrderDetails(orderId, token);

//                 setOrderDetails(response);
//             } catch (error) {
//                 console.error('Error fetching order details:', error);
//             }
//         };

//         fetchOrderDetails();
//     }, [orderId]);

//     return (
//         <Layout Active={'Account Settings'}>
//             <Helmet>
//                 <meta charSet="utf-8" />
//                 <title>Users</title>
//             </Helmet>
//             <div className={UserClasses['main-data-tab']}>
//                 <div className={UserClasses['users-main']}>
//                     <div className={UserClasses['add-users-btn']}>
//                         <button className={UserClasses['main-back-btn']} onClick={() => navigate(-1)}>
//                             Back
//                         </button>
//                     </div>
//                     <div className={UserClasses['users-sub-one']}>
//                         <div className={UserClasses['users-sub-two']}>
//                             <div className={UserClasses['users-div-main']}>
//                                 {orderDetails && (
//                                     <div>
//                                         <img src={BoxImg} className={UserClasses['left-side-image-main']} alt="Box Image" />
//                                         <div className={UserClasses['content-container']}>

//                                             <h4 className={UserClasses['each-heading-two']}>
//                                                 {orderDetails.orderDetails.createdAt}
//                                             </h4>
//                                         </div>
//                                         <div>
//                                             <div className={UserClasses['user-prod-style']}>
//                                                 <h4>Delivery Address</h4>
//                                                 <div className={UserClasses['delivery-data']}>
//                                                     <p><IoPerson className={UserClasses['io-person']} /></p>
//                                                     <p className={UserClasses['users-two-label']}>{orderDetails.orderDetails.userName}</p>
//                                                 </div>
//                                                 <div className={UserClasses['delivery-data']}>
//                                                     <p><IoLocationSharp className={UserClasses['io-person']} /></p>
//                                                     <p className={UserClasses['users-two-label']}>{orderDetails.orderDetails.deliveryAddress}</p>
//                                                 </div>
//                                                 <div className={UserClasses['delivery-data']}>
//                                                     <p><IoIosCall className={UserClasses['io-person']} /></p>
//                                                     <p className={UserClasses['users-two-label']}>{orderDetails.orderDetails.recieverMobileNumber}</p>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div className={UserClasses['user-prod-style']}>
//                                             <h4>Package Items</h4>

//                                         </div>
//                                     </div>
//                                 )}
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </Layout>
//     );
// };

// export default EachUserOrder;

import React, { useEffect, useState } from 'react';
import Layout from 'Components/Layout';
import { Services } from 'Services';
import UserClasses from '../users/Users.module.css';
import { Helmet } from 'react-helmet';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useNavigate, useParams } from 'react-router-dom';
import BoxImg from '../../../Assets/box-img.png';
import { IoPerson } from 'react-icons/io5';
import { IoLocationSharp } from "react-icons/io5";
import { IoIosCall } from "react-icons/io";
import { FaRupeeSign } from "react-icons/fa";
import { Button } from '@mui/material';
import orderbox from '../../../Assets/box-img.png'
import { BiSolidBusiness } from 'react-icons/bi';
import { SlideshowLightbox } from 'lightbox.js-react';
import defaultImage from 'Assets/square-user.jpg'
import noproductimage from '../../../Assets/no-product2.jpg'

const EachUserOrder = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [orderedBy, setOrderedBy] = useState({})
  const [orderedFrom, setOrderedFrom] = useState({})
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setLightboxOpen(true);
  };

  const handleCloseLightbox = () => {
    setSelectedImage('');
    setLightboxOpen(false);
  };

  const navigate = useNavigate()

  const { orderId } = useParams();

  useEffect(() => {
    const token = { Authorization: `token ${localStorage.getItem('Token')}` };

    const fetchData = async () => {
      try {
        setLoading(true);

        const result = await Services.getOrderDetails(orderId, token);

        if (result.Status !== 1) {
          throw new Error('Error fetching data. Please try again');
        }

        setData(result.orderDetails);
        setOrderedBy(result.orderedBy)
        setOrderedFrom(result.orderedFrom)
      } catch (error) {
        console.error('Error fetching data:', error.message);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 200);
      }
    };

    fetchData();
  }, [orderId]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const getStatusLabel = (statusNumber) => {
    switch (statusNumber) {
      case 1:
        return 'New';
      case 2:
        return 'Processing';
      case 3:
        return 'Packed';
      case 4:
        return 'In Transit';
      case 5:
        return 'Delivered';
      case 6:
        return 'Rejected';
      default:
        return 'Unknown Status';
    }
  };

  return (
    <Layout Active={'Account Settings'}>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Users</title>
      </Helmet>

      <div className={UserClasses['main-data-tab']}>
        <div className={UserClasses['users-main']}>
          <div className={UserClasses['add-users-btn']}>
            <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
              BACK
            </Button>
          </div>
          <div className={UserClasses['users-sub-one']}>
            <div className={UserClasses['users-sub-two']}>
              <div className={UserClasses['order-info-main']}>
                <div>
                  <p>OrderId - {data.orderId}</p>
                </div>
              </div>
              <div className={UserClasses['users-div-main']}>
                <div>
                  <img src={orderbox} className={UserClasses['left-side-image-main']} alt="Box Image" />
                </div>
                <div className={UserClasses['content-container']}>
                  <h4 className={UserClasses['each-heading-one']}>{getStatusLabel(data.suplierOrdersStatus)}</h4>
                  <h4 className={UserClasses['each-heading-two']}>{formatDate(data.createdAt)}</h4>
                </div>
              </div>
            </div>
            <div className={UserClasses['users-two']}>
              <SkeletonTheme color="#ddd" highlightColor="#eee">
                {loading ? (
                  <div>
                    <Skeleton className={UserClasses['order-skel']} />
                    <Skeleton className={UserClasses['order-skel']} />
                    <Skeleton className={UserClasses['order-skel']} />
                    <Skeleton className={UserClasses['order-skel']} />
                    <Skeleton className={UserClasses['order-skel']} />
                    <Skeleton className={UserClasses['order-skel']} />
                  </div>
                ) : (
                  <div style={{ overflowY: 'hidden' }}>
                    {data ? (
                      <div>
                        <div className={UserClasses['user-orderfrom']}>
                          <h4>Ordered By</h4>
                          <div className={UserClasses['delivery-data']}>
                            <p><BiSolidBusiness className={UserClasses['io-person']} /></p>
                            <p className={UserClasses['users-two-label']}>Business Name: {orderedBy.businessName}</p>
                          </div>
                          <div className={UserClasses['delivery-data']}>
                            <p><IoIosCall className={UserClasses['io-person']} /></p>
                            <p className={UserClasses['users-two-label']}>Mobile Number: {orderedBy.mobileNumber}</p>
                          </div>
                          <div className={UserClasses['delivery-data']}>
                            <p><IoLocationSharp className={UserClasses['io-person']} /></p>
                            <p className={UserClasses['users-two-label']}>Address: {orderedBy.address}</p>
                          </div>
                        </div>

                        <div className={UserClasses['user-orderfrom']}>
                          <h4>Ordered From</h4>
                          <div className={UserClasses['delivery-data']}>
                            <p><IoPerson className={UserClasses['io-person']} /></p>
                            <p className={UserClasses['users-two-label']}>User Name: {orderedFrom.userName}</p>
                          </div>
                          <div className={UserClasses['delivery-data']}>
                            <p><IoLocationSharp className={UserClasses['io-person']} /></p>
                            <p className={UserClasses['users-two-label']}>Delivery Address: {orderedFrom.deliveryAddress}</p>
                          </div>
                        </div>

                        <div className={UserClasses['user-prod-style']}>
                          <h4>Delivery Address</h4>
                          <div className={UserClasses['delivery-data']}>
                            <p><IoPerson className={UserClasses['io-person']} /></p>
                            <p className={UserClasses['users-two-label']}>Name: {data.userName}</p>
                          </div>
                          <div className={UserClasses['delivery-data']}>
                            <p><IoLocationSharp className={UserClasses['io-person']} /></p>
                            <p className={UserClasses['users-two-label']}>Address: {data.deliveryAddress}</p>
                          </div>
                          <div className={UserClasses['delivery-data']}>
                            <p><IoIosCall className={UserClasses['io-person']} /></p>
                            <p className={UserClasses['users-two-label']}>Mobile Number: {data.recieverMobileNumber}</p>
                          </div>
                        </div>
                        <div className={UserClasses['user-prod-style']}>
                          <h4>Package Items</h4>
                          {data && data.products && data.products.length > 0 ? (
                            data.products.map((product, index) => (
                              <div className={UserClasses['prod-main']} key={index}>
                                <div className={UserClasses['prod-div']}>
                                  <div>
                                    <SlideshowLightbox
                                      isOpen={lightboxOpen}
                                      onClose={handleCloseLightbox}
                                      startIndex={0} // Index of the selected image, if you have a gallery
                                    >
                                      <img className={UserClasses['left-side-image']} src={product.productImage || noproductimage} alt="" onError={(e) => {
                                        e.target.src = noproductimage;
                                      }} />
                                    </SlideshowLightbox>
                                  </div>
                                  <div className={UserClasses['content-container']}>
                                    <h4 className={UserClasses['eachorder-heading-one']}>{product.productName}</h4>
                                    <p className={UserClasses['eachorder-heading-one']}>Qty: {product.qty}</p>
                                  </div>
                                </div>
                                <div className={UserClasses['prod-sec-div']}>
                                  <h6>Price</h6>
                                  <div className={UserClasses['delivery-data']}>
                                    <p><FaRupeeSign className={UserClasses['io-rupee']} /></p>
                                    <p className={UserClasses['price-of-prod']}>{product.price}</p>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <p>No records found</p>
                          )}
                        </div>
                        <div className={UserClasses['order-price-total']}>
                          <h4>Order Price</h4>
                          <div className={UserClasses['delivery-data']}>
                            <p><FaRupeeSign className={UserClasses['io-total']} /></p>
                            <p className={UserClasses['price-of-prod']}>{data.totalPrice}</p>
                          </div>
                        </div>
                        <div className={UserClasses['inside-order-price']}>
                          <div className={UserClasses['order-price-date']}>
                            <h4>Payments</h4>
                          </div>
                          <div className={UserClasses['order-price-total']}>
                            <h4 className={UserClasses['pay-money']}>Payment Date</h4>
                            <h4 className={UserClasses['pay-money']}>Payment Recieved</h4>
                          </div>
                          {data.payments && data.payments.length > 0 ? (
                            <div className={UserClasses['payments-container']}>
                              {data.payments.map((payment) => (
                                <div key={payment.paymentId} className={UserClasses['payment-item']}>
                                  <div className={UserClasses['payment-date']}>
                                    {formatDate(payment.paymentDate)}
                                  </div>
                                  <div className={UserClasses['delivery-data']}>
                                    <p><FaRupeeSign className={UserClasses['io-last']} /></p>
                                    <p className={UserClasses['price-of-prod']}>{payment.receivedPayement}/-</p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <p>No payment records found</p>
                          )}
                        </div>
                      </div>
                    ) : (
                      <p>No records found</p>
                    )}
                  </div>
                )}
              </SkeletonTheme>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EachUserOrder;
