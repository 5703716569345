import Layout from 'Components/Layout'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { AccessControl } from '../Util/AccessControl'
import { AccessKeys } from 'Routes/config'
import UserClasses from '../users/Users.module.css'
import { IoIosAdd } from 'react-icons/io'
import { productCategoryTableHeads, productPriceTableHeads } from '../constants/Constants'
import LoadingSpinnerComponent from 'react-spinners-components'
import { Box, Button, Modal, TextField, Typography } from '@mui/material'
import { Services } from 'Services'
import { Success } from 'Util/Toast'
import { MdOutlineClose } from 'react-icons/md'
import productmoduleclasses from './ProductPrice.module.css'
import { FaRegEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import AOS from 'aos';
import 'aos/dist/aos.css';

const ProductCategory = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedProductPriceId, setSelectedProductPriceId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedProductPriceUnit, setSelectedProductPriceUnit] = useState('');
  const [editedProductPriceUnit, setEditedProductPriceUnit] = useState('');
  const [newUnit, setNewUnit] = useState('');
  const [openAddUnitModal, setOpenAddUnitModal] = useState(false); // Separate state for add unit modal
  const [selectedDeleteProductPriceId, setSelectedDeleteProductPriceId] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const token = { Authorization: `token ${localStorage.getItem('Token')}` };
        const response = await Services.getProductCategoryMeasuringUnit(token);
        setData(response.productCategories);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching product price measuring units:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleEditClick = (productCategoryId, categoryName) => {
    setSelectedProductPriceId(productCategoryId);
    setSelectedProductPriceUnit(categoryName);
    setEditedProductPriceUnit(categoryName);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleUpdateClick = async () => {
    try {
      const updatedData = data.map(item => {
        if (item.productCategoryId === selectedProductPriceId) {
          return { ...item, categoryName: editedProductPriceUnit };
        }
        return item;
      });
      setData(updatedData);
      setOpenModal(false);

      // Perform API call to update the product price unit
      const token = { Authorization: `token ${localStorage.getItem('Token')}` };
      const response = await Services.updateProductCategoryMeasuringUnit(selectedProductPriceId, editedProductPriceUnit, token);
      console.log('Product price unit updated successfully');
      Success(response.Message)
    } catch (error) {
      console.error('Error updating product price unit:', error);
    }
  };

  const handleAddUnitClick = () => {
    setOpenAddUnitModal(true); // Open the add unit modal
  };

  const handleCloseAddUnitModal = () => {
    setOpenAddUnitModal(false); // Close the add unit modal
  };

  const handleAddUnitSubmit = async () => {
    try {
      const token = { Authorization: `token ${localStorage.getItem('Token')}` };
      const response = await Services.addProductCategoryMeasuringUnit(newUnit, token);
      // Assuming the API returns the newly added unit with an ID
      const newUnitData = { productCategoryId: response.productCategoryId, categoryName: newUnit };
      setData([...data, newUnitData]);
      handleCloseAddUnitModal(); // Close the add unit modal after adding unit
      console.log('Product price unit added successfully');
      Success(response.Message);
    } catch (error) {
      console.error('Error adding product price unit:', error);
    }
  };

  const handleDeleteClick = (productCategoryId) => {
    setSelectedDeleteProductPriceId(productCategoryId);
    setOpenDeleteModal(true);
  };

  // Function to handle the deletion confirmation
  const handleConfirmDelete = async () => {
    try {
      // Perform API call to delete the product price unit
      const token = { Authorization: `token ${localStorage.getItem('Token')}` };
      const response = await Services.deleteProductCategoryMeasuringUnit(selectedDeleteProductPriceId, token);

      // Remove the deleted item from the data array
      const updatedData = data.filter(item => item.productCategoryId !== selectedDeleteProductPriceId);
      setData(updatedData);

      setOpenDeleteModal(false);
      console.log('Product price unit deleted successfully');
      Success(response.Message)
    } catch (error) {
      console.error('Error deleting product price unit:', error);
    }
  };

  useEffect(() => {
    AOS.init({
        duration: 1000, // Duration of animation
        once: true, // Whether animation should happen only once
        // Add more options as needed
    });
}, []);

  return (
    <Layout Active={'ProductCategory'}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>ProductCategory</title>
      </Helmet>
      <div className={UserClasses['main-data-tab']}>
        <div className={UserClasses['users-main']}>
          {AccessControl(AccessKeys.ProductCategory)?.write === 1 && (
            <div className={UserClasses['add-users-btn']}>
              <button className={UserClasses['add-user']} onClick={handleAddUnitClick}>
                <IoIosAdd className={UserClasses['add-plus']} />
                ADD CATEGORY
              </button>
            </div>
          )}
          <div className={UserClasses['users-sub-one']}>
            <div className={UserClasses['users-sub-two']}>
              <div className={UserClasses['users-div']}>
                <h4 className={UserClasses['users-heading-one']}>Product Category</h4>
              </div>
              <div>
                <div style={{ overflowY: 'hidden' }}>
                  <table className={UserClasses['Table']}>
                    <thead>
                      <tr>
                        {productCategoryTableHeads.map((head, index) => (
                          <th key={index} className='users-table-data'>{head}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan={productCategoryTableHeads.length}>
                            <LoadingSpinnerComponent type={'Spinner'} color={'green'} size={'100px'} />
                          </td>
                        </tr>
                      ) : data.length > 0 ? (
                        data.map((item, index) => (
                          <tr key={index}>
                            <td>{item.categoryName}</td>
                            <td>
                              <button className={productmoduleclasses['plan-update-prod-btn']} onClick={() => { if (AccessControl(AccessKeys.ProductPrice)?.edit === 1) { handleEditClick(item.productCategoryId, item.categoryName) } }}><FaRegEdit /></button>
                              <button className={productmoduleclasses['plan-delete-prod-btn']} onClick={() => { if (AccessControl(AccessKeys.ProductPrice)?.edit === 1) { handleDeleteClick(item.productCategoryId) } }}><AiFillDelete /></button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={productCategoryTableHeads.length}>
                            No Data Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal open={openModal} onClose={handleCloseModal} data-aos="zoom-in">
        <Box className={productmoduleclasses['modal-container']}>
          <div className={productmoduleclasses['modal-header']}>
            <Typography className={productmoduleclasses['product-typo']} variant="h6" component="h2">
              Edit Product Category Unit
            </Typography>
            <button onClick={handleCloseModal} className={productmoduleclasses['close-button']}>
              <MdOutlineClose />
            </button>
          </div>
          <TextField
            label="Product Category Unit"
            value={editedProductPriceUnit}
            onChange={(e) => setEditedProductPriceUnit(e.target.value)}
            className={productmoduleclasses['prod-typo-textfield']}
          />
          <br />
          <div className={productmoduleclasses['prod-typo-butn']}>
            <Button variant="contained" color="primary" onClick={handleUpdateClick}>Update</Button>
          </div>
        </Box>
      </Modal>
      <Modal open={openAddUnitModal} onClose={handleCloseAddUnitModal} data-aos="zoom-in">
        <Box className={productmoduleclasses['modal-container']}>
          <div className={productmoduleclasses['modal-header']}>
            <Typography className={productmoduleclasses['product-typo']} variant="h6" component="h2">
              Add Product category Unit
            </Typography>
            <button onClick={handleCloseAddUnitModal} className={productmoduleclasses['close-button']}>
              <MdOutlineClose />
            </button>
          </div>
          <TextField
            label="New Unit"
            value={newUnit}
            onChange={(e) => setNewUnit(e.target.value)}
            className={productmoduleclasses['prod-typo-textfield']}
          />
          <br />
          <div className={productmoduleclasses['prod-typo-butn']}>
            <Button variant="contained" color="primary" onClick={handleAddUnitSubmit}>Submit</Button>
          </div>
        </Box>
      </Modal>
      <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)} data-aos="zoom-in">
        <Box className={productmoduleclasses['modal-container']}>
          <Typography className={productmoduleclasses['product-typo']} variant="h6" component="h2">
            Confirm Deletion
          </Typography>
          <Typography variant="body1" gutterBottom>
            Are you sure you want to delete this product category unit?
          </Typography>
          <div className={productmoduleclasses['prod-typo-butn-new']}>
            <Button variant="contained" color="primary" onClick={handleConfirmDelete}>Confirm</Button>
            <Button variant="outlined" color="secondary" onClick={() => setOpenDeleteModal(false)}>Cancel</Button>
          </div>
        </Box>
      </Modal>
    </Layout>
  )
}

export default ProductCategory
