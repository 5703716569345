export const userTableHeads = [
    'NAME',
    'MOBILE NUMBER',
    'EMAIL',
    'BUSINESS NAME',
    'GST NUMBER',
    'SHOP STATUS',
    'BLOCK UNBLOCK',
  ];
  
export const userDetailsTableHeads = [
    'Photo',
    'name',
    'email',
    'mobileNumber',
    'businessName',
    'AccountType',
    'ACTIONS',
  ];
  
  export const productsTableHeads = [
    'PRODUCT IMAGE',
    'PRODUCT NAME',
    'BASE QTY',
    'STATUS',
  ];
  
  export const groupsTableHeads = [
    'GROUP NAME',
    'CREATED ON',
  ];
  
  export const suppliersTableHeads = [
    'PHOTO',
    'NAME',
    'EMAIL',
    'MOBILE',
    'BUSINESS',
    'ACCOUNT TYPE',
  ];

  export const groupUsersTableHeads = [
    'PHOTO',
    'NAME',
    'MOBILE',
    'EMAIL',
    'BUSINESS',
    'GST NUMBER',
  ];

  export const groupProductsTableHeads = [
    'PRODUCT IMAGE',
    'PRODUCT NAME',
    'PRICE',
    'MANUFACTURED ON',
  ];

  export const allOrderTableHeads = [
    'ORDER ID',
    'NAME',
    'CREATED AT',
    'PENDING PAYMENT',
    'RECIEVED PAYMENT',
    'TOTAL PRICE',
    'REJECTION REASON',
  ]
  
  export const subscriptionPlansTableHeads = [
    'ACCOUNT TYPE',
    'BASE USERS',
    'MONTHLY PRICE',
    'PRICE PER EXTRA USER',
    'SUBSCRIPTION CONTENT',
    'ACTIONS',
  ]

  export const recievedOrdersTableHeads = [
    'USER NAME',
    'CREATED AT',
    'STATUS',
  ]

  export const createdOrdersTableHeads = [
    'USER NAME',
    'CREATED AT',
    'STATUS',
  ]

  export const createConfigTableHeads = [
    'KEY',
    'VALUE',
    'CREATED AT',
    'UPDATED AT',
    'ACTIONS',
  ]

  export const pendingBillsTableHeads = [
    'CUSTOMER NAME',
    'AMOUNT',
    'DUE DATE',
  ]

  export const cancelledBillsTableHeads = [
    'CUSTOMER NAME',
    'AMOUNT',
    'DUE DATE',
  ]

  export const productPriceTableHeads = [
    'PRICE UNIT',
    'ACTIONS',
  ]

  export const productCategoryTableHeads = [
    'CATEGORY UNIT',
    'ACTIONS',
  ]

  export const userGuideTableHeads = [
    'DOCUMENT',
    'CREATED AT',
    'UPDATED AT',
    'ACTIONS',
  ]